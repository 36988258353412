import React from 'react';
import Fade from "react-reveal";
import logo1 from "../assets/images/Logo 1.png";
import logo2 from "../assets/images/Logo 2.png";

function Submit(e) {
    e.preventDefault();
    const formEle = document.querySelector("form");
    const formData = new FormData(formEle);

    fetch("https://script.google.com/macros/s/AKfycbyWGTInsob8p49cuk0gKo5Zc-1r5gczIrSiEsiSSAlHeCcMUBB17PV2tQ7lsUtRgFq4/exec", {
        method: "POST",
        body: formData
    })
        .then(response => {
            if (response.ok) {
                console.log("Form submitted successfully!");
            } else {
                console.error("Form submission failed.");
            }
        });
}

function Form() {
    return (
        <section id="portfolio">
            <Fade left duration={1000} distance="40px">
                <div className="row">
                    <div className="three columns">
                        {/* Lewe zdjęcie */}
                        <img id="loga" src={logo1} alt="Tło" className="logo" width={'200px'} style={{ marginTop: '40%' }} />
                    </div>
                    <div className="six columns form-container">
                        {/* Formularz */}
                        <form className="form" onSubmit={(e) => Submit(e)}>
                            <h1>Rejestracja na wydarzenie</h1>
                            <div className="input-style">
                                <label htmlFor='name'>Imie *</label>
                                <input type="text" id="name" name="Name" placeholder='Twoje imię' required />
                            </div>
                            <div className="input-style">
                                <label htmlFor='email'>Email *</label>
                                <input type="email" id="email" name="Email" placeholder='Twój adres email' required />
                            </div>
                            <div className="input-style" style={{ textAlign: 'center' }}>
                                <input className="button" id="item-but" type="submit" value="Wyślij" />
                            </div>
                        </form>
                    </div>
                    <div className="three columns">
                        {/* Prawe zdjęcie */}
                        <img id="loga" src={logo2} alt="Tło" className="logo" width={'200px'} style={{ marginTop: '40%' }} />
                    </div>
                </div>
            </Fade >
        </section >
    );
}

export default Form;
