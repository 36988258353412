import inaug1 from "../assets/images/img0.jpg";
import inaug2 from "../assets/images/img1.jpg";
import styles from "./Resume.module.css";
import Slide from "react-reveal";

const Desc = () => {
  return (
    <section id="resume">
      <Slide left duration={1300}>
        <div className={styles.grid_container}>
          <div className={styles.image}>
            <img src={inaug1} alt="Zdjęcie 1" />
          </div>
          <div className={styles.image_description}>
            <p>Już w ostatni czwartek listopada widzimy się jak co roku w nowym miejscu. O godzinie 18 w Miłostanie w Zabrzu rozpocznie się wykład dwóch utalentowanych i odnoszących sukcesy kobiet, które chętnie przekażą swoją wiedzę oraz zmotywują do działania każdą z Nas. W jakim celu? Aby kobiety mogły odnieść sukces i rozwinąć się w każdym momencie swojego życia oraz pokazać, że mają nieograniczone możliwości w zakresie zawodowym. Chętnych zapraszamy do udziału w dyskusji przy pysznych specjałach kawiarni Miłostan. Razem uczyńmy ten wieczór inspirującym i wyjątkowym!
            </p>
          </div>

          <div className={styles.image_description}>
            <p>Kobiety, które wspierają siebie nawzajem to jest coś na co możemy patrzeć całymi dniami. Do tego chcemy dążyć i dawać możliwość rozwijania się. Dzięki takim spotkaniom staramy się przekazać i uświadomić, że każda kobieta, bez względu na jakim etapie życia się znajduje, jest w stanie osiągną naprawdę wiele. Jest wiele przeszkód, na które możemy się natknąć w rozwijaniu swoich celów, ale nie ma rzeczy, której nie da się przełamać. Najlepszy sposób udowodnienia to spotkanie "twarzą w twarz" z osobami, które przeszły naprawdę wiele i są spełnione w tym co robią.
            </p>
          </div>
          <div className={styles.image}>
            <img src={inaug2} alt="Zdjęcie 2" />
          </div>
        </div>
      </Slide>
    </section >
  );
};

export default Desc;
